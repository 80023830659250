<template>
  <div>
    <Menu :menu="accessMenu" prefix="" />
    <transition name="page">
      <router-view />
    </transition>
  </div>
</template>

<script>
import Menu from '@/components/atoms/Menu.vue'
import { ADDWINE_ADMIN_ROUTES } from '@/constants/routing'
import { mapGetters } from 'vuex'

export default {
  components: {
    Menu,
  },
  data() {
    return {
      menu: [
        {
          title: 'Способы оплаты',
          link: ADDWINE_ADMIN_ROUTES.PAYMENTS.PAYMENT_TYPES.INDEX,
        },
        {
          title: 'Локации доставки',
          link: ADDWINE_ADMIN_ROUTES.PAYMENTS.LOCATIONS_DELIVERY.INDEX,
        },
        {
          title: 'Статусы заказа',
          link: ADDWINE_ADMIN_ROUTES.PAYMENTS.ORDER_STATUSES.INDEX,
        },
        {
          title: 'Статусы\xa0оплаты\xa0заказа',
          link: ADDWINE_ADMIN_ROUTES.PAYMENTS.PAYMENT_STATUSES.INDEX,
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      filterAccessMenuList: 'filterAccessMenuList',
    }),
    accessMenu() {
      return this.filterAccessMenuList(this.menu)
    },
  },
}
</script>
